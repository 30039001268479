<template>
  <div class="pgoffice-page">
    <div id="pageOfficeContent" v-html="html_code" class="office-main"></div>
  </div>
</template>

<script>
import { viewOldData } from "@/api/order"
export default {
  name: "order_excel_",
  data: function () {
    return {
      html_code: "",
      params: "",
      text: "当前数据未获取",
    };
  },
  methods: {
    Save: function () {
      window.external.close();
    },
    Close: function () {
      window.external.close();
    },
    getParam: function () {
      this.params = window.external.UserParams;
      if (this.params) {
        this.params = JSON.parse(decodeURI(this.params));
      }
    },
    init: function () {
      this.getParam();
      if (!this.params || this.params == "" || this.params == undefined) {
        alert("未获取到参数,打开失败");
        return;
      }
      viewOldData(this.params).then((res) => {
        this.html_code = res;
      });
    },
  },
  created: function () {
    window.BeforeDocumentSaved = function (e) {
      console.log(e);
    };
    window.Save = this.Save;
    window.Close = this.Close;
    window.onCellClick = this.onCellClick;
    this.init();
  },
};
</script>

<style scoped lang="less">
.pgoffice-page {
  position: relative;
}

.tool {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  row-gap: 20px;
}

.text {
  padding: 0 20px;
  box-sizing: border-box;
  font-size: 28px;
}

.btn {
  background-color: #366ab3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 240px;
  word-break: keep-all;
  border: none;
  border-radius: 4px;
}
</style>